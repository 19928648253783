import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useGetTaetigkeitsbericht,
  useGetPersonaleintrag,
  useSucheFuhrparkeintraege,
  useSucheFuhrparkeintragAnbauteil,
  useUpdateFuhrparkeintrag,
  useUpdateFuhrparkeintragAnbauteil,
  useUpdateTaetigkeitsbericht,
  useUpdatePersonaleintrag,
  useCreateMaschinentransport,
  useUpdateMaschinentransport,
  useSucheMaschinentransporte,
  useUpdateMaterialtransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useSucheMaterialtransporteAnfuhr,
  useSucheMaterialtransporteAbtransport,
  useGetMaterialtransporteZwischenListe,
  useUpdateMaterialtransportZwischen,
} from "../../client/hooks";
import {
  Fuhrparkeintrag,
  FuhrparkeintragAnbauteil,
  Taetigkeitsbericht,
  Personaleintrag,
  Benutzer,
  Abladestelle,
  Buchungstyp,
  EinheitenListe,
  ActionTimestamp,
  Material,
  Baustelle,
  Fahrzeug,
  Entsorger,
  Erzeuger,
  ZugewiesenesFahrzeugAnbauteil,
  Abfallart,
  TaetigkeitsberichtDaten,
} from "../../api";
import TaetigkeitsberichtFieldsContent from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContent";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTaetigkeitsberichtButton from "../../components/taetigkeitsberichte/DeleteTaetigkeitsberichtButton";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";
import DeleteFuhrparkeintragButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/DeleteFuhrparkeintragButton";
import ErrorAlert from "../../components/ErrorAlert";
import { notPermitted } from "../../errors";
import Guard from "../../components/Guard";

const FuhrparkeintragEigenPersonalItem = React.lazy(
  () =>
    import(
      "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem"
    )
);

interface MaschinentransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  baustelleId: string;
  transportiertesFahrzeug: any;
  fuhrTyp: Buchungstyp;
  bemerkung?: string;
  lieferscheinNummer?: string;
  abholortBaustelle?: any;
  abstellortBaustelle?: any;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface MaterialTransportDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  fuhrTyp: Buchungstyp;
  material?: any;
  einheit: EinheitenListe;
  anzahlFuhren: number;
  baustelleId: string;
  zielBaustelle?: any;
  zielAbladestelle?: any;
  quelleBaustelle?: any;
  quelleAbladestelle?: any;
  menge: number;
  kostenProEinheit: number;
  mengeGesamt: number;
  preisGesamt: number;
  buchungsTyp: string;
  bemerkung?: string;
  lieferscheinNummer?: string;
  rechnung_id?: string;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
  meLadeVolumenFahrzeug?: number;
  buchungsArt?: string;
  bsNr?: string;
  entsorger?: any;
  erzeuger?: any;
  abfallart?: any;
}

interface ExtendedFuhrparkeintragAnbauteilDaten {
  id: string;
  taetigkeitsberichtId: string;
  fuhrparkeintragId: string;
  anbauteil: any;
  anbauteilNutzungsdauer: number;
  anbauteilAnzahl: number;
  anbauteilStundenPreis: number;
  anbauteilKosten: number;
  created: ActionTimestamp;
  updated?: ActionTimestamp;
  deleted?: ActionTimestamp;
}

interface FuhrparkeintragData {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeug: any;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  datum?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: ExtendedFuhrparkeintragAnbauteilDaten[];
  maschinentransporte: MaschinentransportDaten[];
  materialanfuhr: MaterialTransportDaten[];
  materialabtransport: MaterialTransportDaten[];
  materialtransport: MaterialTransportDaten[];
}

interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiter: any;
  baustelle: any;
  datum: string;
  arbeitszeitNetto: number | null;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: FuhrparkeintragData[];
}

const validationSchema = Yup.object().shape({
  mitarbeiter: Yup.lazy((value) =>
    typeof value === "string"
      ? Yup.string().required("Mitarbeiter ist erforderlich!")
      : Yup.object().required("Mitarbeiter ist erforderlich!")
  ),
  baustelle: Yup.lazy((value) =>
    typeof value === "string"
      ? Yup.string().required("Baustelle ist erforderlich!")
      : Yup.object().required("Baustelle ist erforderlich!")
  ),
  datum: yup
    .date()
    .required("Datum ist erforderlich!")
    .max(
      new Date("9999-12-31"),
      "Datum darf nicht über dem 31.12.9999 liegen!"
    ),
  arbeitszeitNetto: yup.number().nullable().min(0),
  bemerkung: yup.string(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeug: Yup.lazy((value) =>
          typeof value === "string"
            ? Yup.string().required("Fahrzeug ist erforderlich!")
            : Yup.object().required("Fahrzeug ist erforderlich!")
        ),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        datum: yup
          .date()
          .nullable()
          .max(
            new Date("9999-12-31"),
            "Datum darf nicht über dem 31.12.9999 liegen!"
          ),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteil: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("anbauteil is required")
                  : Yup.object().required("anbauteil is required")
              ),
              anbauteilNutzungsdauer: yup
                .number()
                .min(0, "Nutzungsdauer muss mindestens 0 sein!")
                .required("Nutzungsdauer ist erforderlich!"),
              anbauteilStundenPreis: yup
                .number()
                .min(0, "Preis muss mindestens 0 sein!")
                .required("Preis ist erforderlich!"),
              anbauteilAnzahl: yup
                .number()
                .min(1, "Stückzahl muss mindestens 0 sein!")
                .required("Stückzahl ist erforderlich!"),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
        maschinentransporte: Yup.array()
          .of(
            Yup.object().shape({
              transportiertesFahrzeug: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required(
                      "transportiertesFahrzeug ist erforderlich!"
                    )
                  : Yup.object().required(
                      "transportiertesFahrzeug ist erforderlich!"
                    )
              ),
              abholortBaustelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required("abholortBaustelle ist erforderlich!")
                  : Yup.object().required("abholortBaustelle ist erforderlich!")
              ),
              abstellortBaustelle: Yup.lazy((value) =>
                typeof value === "string"
                  ? Yup.string().required(
                      "abstellortBaustelle ist erforderlich!"
                    )
                  : Yup.object().required(
                      "abstellortBaustelle ist erforderlich!"
                    )
              ),
              lieferscheinNummer: yup.string(),
              bemerkung: yup.string().nullable(),
            })
          )
          .min(0, ""),
        materialanfuhr: Yup.array().of(
          Yup.object().shape({
            quelleAbladestelle: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("quelleAbladestelle ist erforderlich!")
                : Yup.object().required("quelleAbladestelle ist erforderlich!")
            ),
            material: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("material ist erforderlich!")
                : Yup.object().required("material ist erforderlich!")
            ),
            meLadeVolumenFahrzeug: yup
              .number()
              .min(0, "Ladevolumen muss mindestens 0 sein!")
              .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialabtransport: Yup.array().of(
          Yup.object().shape({
            zielAbladestelle: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("zielAbladestelle ist erforderlich!")
                : Yup.object().required("zielAbladestelle ist erforderlich!")
            ),
            meLadeVolumenFahrzeug: yup
              .number()
              .min(0, "Ladevolumen muss mindestens 0 sein!")
              .required("Ladevolumen ist erforderlich!"),
            material: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("material ist erforderlich!")
                : Yup.object().required("material ist erforderlich!")
            ),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            bsNr: yup.string().nullable(),
            erzeuger: yup.object().nullable(),
            entsorger: yup.object().nullable(),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialtransport: Yup.array().of(
          Yup.object().shape({
            zielBaustelle: Yup.lazy((value) =>
              typeof value === "string"
                ? Yup.string().required("zielAbladestelle is required")
                : Yup.object().required("zielAbladestelle is required")
            ),
            meLadeVolumenFahrzeug: yup
              .number()
              .min(0, "Ladevolumen muss mindestens 0 sein!")
              .required("Ladevolumen ist erforderlich!"),
            kostenProEinheit: yup
              .number()
              .min(0, "Preis muss mindestens 0 sein!")
              .required("Preis ist erforderlich!"),
            anzahlFuhren: yup
              .number()
              .min(1, "Die Anzahl der Fuhren muss mindestens 1 sein!")
              .required("Anzahl von Fuhren ist erforderlich!"),
            einheit: yup.string().required("Einheit ist erforderlich!"),
            menge: yup
              .number()
              .min(0, "Menge muss mindestens 0 sein!")
              .required("Menge ist erforderlich!"),
            lieferscheinNummer: yup.string().nullable(),
            bemerkung: yup.string().nullable(),
            abfallart: yup.object().nullable(),
          })
        ),
      })
    )
    .min(0, ""),
});

export default function TaetigkeitsberichtDetails() {
  const { id: taetigkeitsberichtId = "" } = useParams<{
    id: string;
  }>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stringExpandedindexInherited: string | null = queryParams.get(
    "expandedindexInherited"
  );
  const expandedindexInherited: number | null = stringExpandedindexInherited
    ? parseInt(stringExpandedindexInherited)
    : null;

  const stringAddedFPInherited: string | null = queryParams.get("addedFP");
  const addedFPInherited: boolean = stringAddedFPInherited
    ? stringAddedFPInherited === "true"
    : false;
  const { t } = useTranslation("taetigkeitsbericht");
  const navigate = useNavigate();
  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const updateTaetigkeitsbericht = useUpdateTaetigkeitsbericht();
  const getPersonaleintrag = useGetPersonaleintrag();
  const updatePersonaleintrag = useUpdatePersonaleintrag();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const updateFuhrparkeintrag = useUpdateFuhrparkeintrag();
  const searchFuhrparkeintraege = useSucheFuhrparkeintraege();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const updateFuhrparkeintragAnbauteil = useUpdateFuhrparkeintragAnbauteil();
  const searchFuhrparkeintragAnbauteil = useSucheFuhrparkeintragAnbauteil();
  const createMaschinentransport = useCreateMaschinentransport();
  const updateMaschinentransport = useUpdateMaschinentransport();
  const searchMaschinentransport = useSucheMaschinentransporte();
  const searchMaterialtransportAnfuhr = useSucheMaterialtransporteAnfuhr();
  const searchMaterialtransporteAbtransport =
    useSucheMaterialtransporteAbtransport();
  const searchMaterialZwischenBaustellen =
    useGetMaterialtransporteZwischenListe();
  const updateMaterialtransport = useUpdateMaterialtransport();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();
  const updateMaterialtransportZwischenBaustellen =
    useUpdateMaterialtransportZwischen();
  const [fuhrparkeintraege, setFuhrparkeintraege] = useState<
    FuhrparkeintragData[]
  >([]);
  const [taetigkeitsbericht, setTaetigkeitsbericht] =
    useState<Taetigkeitsbericht | null>(null);

  const [personaleintrag, setPersonaleintrag] =
    useState<Personaleintrag | null>(null);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(
    expandedindexInherited
  );
  const localStorageKey = "expandedItems";
  const [expandedItems, setExpandedItems] = useState<boolean[]>(() =>
    JSON.parse(localStorage.getItem(localStorageKey) || "[]")
  );

  const [berichtOffen, setBerichtOffen] = useState<boolean>(
    taetigkeitsbericht?.status === "OFFEN"
  );

  useEffect(() => {
    setBerichtOffen(taetigkeitsbericht?.status === "OFFEN");
  }, [taetigkeitsbericht]);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    setExpandedItems((prevState) => {
      const newExpandedItems = [...prevState];
      newExpandedItems[index] = !newExpandedItems[index];
      localStorage.setItem(localStorageKey, JSON.stringify(newExpandedItems));
      return newExpandedItems;
    });
  };
  const setExpandedItemsState = (indexLength: number) => {
    let index = indexLength ?? 1;
    setExpandedItems((prevState) => {
      const newExpandedItems = [...prevState];
      newExpandedItems[index] = true;
      localStorage.setItem(localStorageKey, JSON.stringify(newExpandedItems));
      return newExpandedItems;
    });
  };
  enum Buchungstyp {
    AR = "AR",
    ER = "ER",
    PS = "PS",
    FP = "FP",
    MA = "MA",
    AB = "AB",
    DP = "DP",
    TR = "TR",
  }

  const [dauerVorhanden, setDauerVorhanden] = useState<number>(
    personaleintrag?.arbeitszeitNetto ?? 0
  );

  useEffect(() => {
    const fetchFuhrparkeintraege = async () => {
      if (taetigkeitsberichtId) {
        const fuhrparkeintraegeData = await searchFuhrparkeintraege(
          taetigkeitsberichtId,
          {
            search: taetigkeitsberichtId,
            page: 0,
            limit: 20,
            sort: "created",
          }
        );

        const completeFuhrparkeintraege = await Promise.all(
          fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
            const fuhrparkeintragAnbauteileData =
              await searchFuhrparkeintragAnbauteil(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );

            const maschinentransporteData = await searchMaschinentransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialanfuhrData = await searchMaterialtransportAnfuhr(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialabtransportData =
              await searchMaterialtransporteAbtransport(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );
            const materialzwischenData = await searchMaterialZwischenBaustellen(
              taetigkeitsberichtId,
              fuhrparkeintrag.id
            );
            const materialanfuhrListe = materialanfuhrData.items;
            const abtranporteListe = materialabtransportData.items;
            const zwischenListe = materialzwischenData.items;
            return {
              ...fuhrparkeintrag,
              fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
              maschinentransporte: maschinentransporteData.items,
              materialanfuhr: materialanfuhrListe,
              materialabtransport: abtranporteListe,
              materialtransport: zwischenListe,
            };
          })
        );

        // @ts-ignore
        setFuhrparkeintraege(completeFuhrparkeintraege);
      }
    };
    fetchFuhrparkeintraege();
  }, [taetigkeitsberichtId, searchFuhrparkeintraege]);

  useEffect(() => {
    const fetchTaetigkeitsbericht = async () => {
      if (taetigkeitsberichtId) {
        const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
        setTaetigkeitsbericht(data);
      }
    };
    const fetchPersonaleintrag = async () => {
      if (taetigkeitsberichtId) {
        const data = await getPersonaleintrag(taetigkeitsberichtId);
        setPersonaleintrag(data);
      }
    };
    fetchTaetigkeitsbericht();
    fetchPersonaleintrag();
  }, [taetigkeitsberichtId, getTaetigkeitsbericht, getPersonaleintrag]);

  useEffect(() => {
    var aktuelleVorhandeneDauer = personaleintrag?.arbeitszeitNetto ?? 0;
    fuhrparkeintraege.map((fuhrparkeintrag) => {
      aktuelleVorhandeneDauer -= fuhrparkeintrag.fahrzeugNutzungsdauer;
    });
    setDauerVorhanden(
      aktuelleVorhandeneDauer > 0 ? aktuelleVorhandeneDauer : 0
    );
  }, [taetigkeitsbericht, fuhrparkeintraege, personaleintrag]);

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: Fuhrparkeintrag[];
    }
  ) {
    try {
      const mitarbeiterId =
        typeof values.mitarbeiter === "string"
          ? values.mitarbeiter
          : (values.mitarbeiter as Benutzer)?.id ?? "";
      const baustelleId =
        typeof values.baustelle === "string"
          ? values.baustelle
          : (values.baustelle as Baustelle)?.id ?? "";
      const taetigkeitsbericht = await updateTaetigkeitsbericht(
        taetigkeitsberichtId,
        {
          baustelleId: baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "INTERN",
          status: "ANGENOMMEN",
        }
      );

      const personaleintrag = await updatePersonaleintrag(
        taetigkeitsberichtId,
        {
          mitarbeiterId: mitarbeiterId,
          baustelleId: baustelleId,
          datum: values.datum,
          arbeitszeitNetto: values.arbeitszeitNetto ?? undefined,
          stundensatz: values.stundensatz,
          bemerkung: values.bemerkung,
        }
      );

      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId:
              typeof fuhrparkeintrag.fahrzeug === "string"
                ? fuhrparkeintrag.fahrzeug
                : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? "",
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
            datum: fuhrparkeintrag.datum,
          };
          if (fuhrparkeintrag.id) {
            return updateFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              fuhrparkeintragData
            );
          } else {
            return createFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintragData
            );
          }
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId:
                  typeof fuhrparkeintragAnbauteil.anbauteil === "string"
                    ? fuhrparkeintragAnbauteil.anbauteil
                    : (
                        fuhrparkeintragAnbauteil.anbauteil as ZugewiesenesFahrzeugAnbauteil
                      )?.anbauteilId ?? "",
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              if (fuhrparkeintragAnbauteil.id) {
                return updateFuhrparkeintragAnbauteil(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteil.id,
                  fuhrparkeintragAnbauteilData
                );
              } else {
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);
      const maschinentransportePromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.maschinentransporte.map(
            (maschinentransport) => {
              const abholortBaustellenId =
                typeof maschinentransport.abholortBaustelle === "string"
                  ? maschinentransport.abholortBaustelle
                  : (maschinentransport.abholortBaustelle as Baustelle)?.id ??
                    "";
              const abstellortBaustellenId =
                typeof maschinentransport.abstellortBaustelle === "string"
                  ? maschinentransport.abstellortBaustelle
                  : (maschinentransport.abstellortBaustelle as Baustelle)?.id ??
                    null;
              const maschinentransportData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                transportiertesFahrzeugId:
                  typeof maschinentransport.transportiertesFahrzeug === "string"
                    ? maschinentransport.transportiertesFahrzeug
                    : (maschinentransport.transportiertesFahrzeug as Fahrzeug)
                        ?.id ?? "",
                abholortBaustellenId: abholortBaustellenId,
                abstellortBaustellenId: abstellortBaustellenId,
                fuhrTyp: Buchungstyp.TR,
                lieferscheinNummer: maschinentransport.lieferscheinNummer,
                bemerkung: maschinentransport.bemerkung,
              };
              if (maschinentransport.id) {
                return updateMaschinentransport(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  maschinentransport.id,
                  maschinentransportData
                );
              } else {
                return createMaschinentransport(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  maschinentransportData
                );
              }
            }
          );
        }
      );

      await Promise.all(maschinentransportePromises);
      const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
            const quelleAbladestelleId =
              typeof materialanfuhr.quelleAbladestelle === "string"
                ? materialanfuhr.quelleAbladestelle
                : (materialanfuhr.quelleAbladestelle as Abladestelle)?.id ??
                  null;
            const materialId =
              typeof materialanfuhr.material === "string"
                ? materialanfuhr.material
                : (materialanfuhr.material as Material)?.id ?? "";
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              quelleAbladestelleId: quelleAbladestelleId,
              zielBaustelleId: materialanfuhr.zielBaustelle,
              materialId: materialId,
              buchungsTyp: Buchungstyp.MA,
              fuhrTyp: Buchungstyp.MA,
              lieferscheinNummer: materialanfuhr.lieferscheinNummer,
              bemerkung: materialanfuhr.bemerkung,
              einheit: materialanfuhr.einheit,
              anzahlFuhren: materialanfuhr.anzahlFuhren,
              kostenProEinheit: materialanfuhr.kostenProEinheit,
              menge: materialanfuhr.menge,
              meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
              buchungsArt: materialanfuhr.buchungsArt,
            };

            if (materialanfuhr.id) {
              return updateMaterialtransport(
                taetigkeitsberichtId,
                currentFuhrparkeintragId,
                materialanfuhr.id,
                materialanfuhrData
              );
            } else {
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          });
        }
      );

      await Promise.all(materialanfuhrPromises);

      const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialabtransport.map((materialtranport) => {
            const zielAbladestelleId =
              typeof materialtranport.zielAbladestelle === "string"
                ? materialtranport.zielAbladestelle
                : (materialtranport.zielAbladestelle as Abladestelle)?.id ?? "";
            const materialId =
              typeof materialtranport.material === "string"
                ? materialtranport.material
                : (materialtranport.material as Material)?.id ?? null;
            const entsorgerId =
              typeof materialtranport.entsorger === "string"
                ? materialtranport.entsorger
                : (materialtranport.entsorger as Entsorger)?.id ?? null;
            const erzeugerId =
              typeof materialtranport.erzeuger === "string"
                ? materialtranport.erzeuger
                : (materialtranport.erzeuger as Erzeuger)?.id ?? null;
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              zielAbladestelleId: zielAbladestelleId,
              materialId: materialId,
              buchungsTyp: materialtranport.buchungsTyp,
              fuhrTyp: materialtranport.fuhrTyp,
              lieferscheinNummer: materialtranport.lieferscheinNummer,
              bemerkung: materialtranport.bemerkung,
              einheit: materialtranport.einheit,
              anzahlFuhren: materialtranport.anzahlFuhren,
              kostenProEinheit: materialtranport.kostenProEinheit,
              menge: materialtranport.menge,
              buchungsArt: materialtranport.buchungsArt,
              bsNr: materialtranport.bsNr,
              entsorgerId: entsorgerId,
              erzeugerId: erzeugerId,
            };
            if (materialtranport.id) {
              return updateMaterialtransport(
                taetigkeitsberichtId,
                currentFuhrparkeintragId,
                materialtranport.id,
                materialanfuhrData
              );
            } else {
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          });
        }
      );

      await Promise.all(materialabtransportPromises);

      const materialtransportPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialtransport.map((materialtranport) => {
            const zielBaustelleId =
              typeof materialtranport.zielBaustelle === "string"
                ? materialtranport.zielBaustelle
                : (materialtranport.zielBaustelle as Baustelle)?.id ?? null;
            const abfallartId =
              typeof materialtranport.abfallart === "string"
                ? materialtranport.abfallart
                : (materialtranport.abfallart as Abfallart)?.id ?? null;
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              quelleBaustelleId: taetigkeitsbericht.baustelleId,
              zielBaustelleId: zielBaustelleId,
              materialId: materialtranport.material,
              buchungsTyp: materialtranport.buchungsTyp,
              lieferscheinNummer: materialtranport.lieferscheinNummer,
              bemerkung: materialtranport.bemerkung,
              einheit: materialtranport.einheit,
              anzahlFuhren: materialtranport.anzahlFuhren,
              kostenProEinheit: materialtranport.kostenProEinheit,
              menge: materialtranport.menge,
              buchungsArt: materialtranport.buchungsArt,
              abfallartId: abfallartId,
            };
            if (materialtranport.id) {
              return updateMaterialtransportZwischenBaustellen(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialtranport.id,
                materialanfuhrData
              );
            } else {
              return createMaterialtransportZwischenBaustellen(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          });
        }
      );

      await Promise.all(materialtransportPromises);

      const fuhrparkeintraegeData = await searchFuhrparkeintraege(
        taetigkeitsberichtId,
        {
          search: taetigkeitsberichtId,
          page: 0,
          limit: 20,
          sort: "created",
        }
      );

      const completeFuhrparkeintraege = await Promise.all(
        fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
          const fuhrparkeintragAnbauteileData =
            await searchFuhrparkeintragAnbauteil(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );

          const maschinentransportData = await searchMaschinentransport(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialanfuhrData = await searchMaterialtransportAnfuhr(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialabtransportData =
            await searchMaterialtransporteAbtransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
          const materialzwischenData = await searchMaterialZwischenBaustellen(
            taetigkeitsberichtId,
            fuhrparkeintrag.id
          );
          const materialanfuhrListe = materialanfuhrData.items;
          const abtranporteListe = materialabtransportData.items;
          return {
            ...fuhrparkeintrag,
            fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
            maschinentransporte: maschinentransportData.items,
            materialanfuhr: materialanfuhrListe,
            materialabtransport: abtranporteListe,
            materialtransport: materialzwischenData.items,
          };
        })
      );

      // @ts-ignore
      setFuhrparkeintraege(completeFuhrparkeintraege);
      const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
      setTaetigkeitsbericht(data);
      const dataPersonaleintrag = await getPersonaleintrag(
        taetigkeitsberichtId
      );
      setPersonaleintrag(dataPersonaleintrag);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten,
      formikHelpers: FormikHelpers<ExtendedTaetigkeitsberichtDaten>,
      abc: string
    ) => {
      const { setSubmitting, setStatus, resetForm } = formikHelpers;
      const tb = await getTaetigkeitsbericht(taetigkeitsberichtId);
      const alterTaetigkeitsberichtstatus = tb?.status;
      const mitarbeiterId =
        typeof values.mitarbeiter === "string"
          ? values.mitarbeiter
          : (values.mitarbeiter as Benutzer)?.id ?? "";
      const baustelleId =
        typeof values.baustelle === "string"
          ? values.baustelle
          : (values.baustelle as Baustelle)?.id ?? "";
      try {
        const taetigkeitsberichtUpdatedData: TaetigkeitsberichtDaten =
          alterTaetigkeitsberichtstatus === "OFFEN"
            ? {
                baustelleId: baustelleId,
                datum: values.datum,
                taetigkeitsberichtTyp: "INTERN",
                status: "ANGENOMMEN",
              }
            : {
                baustelleId: baustelleId,
                datum: values.datum,
                taetigkeitsberichtTyp: "INTERN",
                status: "ANGENOMMEN",
              };

        const updatedTaetigkeitsbericht = await updateTaetigkeitsbericht(
          taetigkeitsberichtId,
          taetigkeitsberichtUpdatedData
        );

        const updatedPersonaleintrag = await updatePersonaleintrag(
          taetigkeitsberichtId,
          {
            mitarbeiterId: mitarbeiterId,
            baustelleId: baustelleId,
            datum: values.datum,
            arbeitszeitNetto: values.arbeitszeitNetto ?? undefined,
            stundensatz: values.stundensatz,
            bemerkung: values.bemerkung,
          }
        );

        // Create or update each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId:
                typeof fuhrparkeintrag.fahrzeug === "string"
                  ? fuhrparkeintrag.fahrzeug
                  : (fuhrparkeintrag.fahrzeug as Fahrzeug)?.id ?? "",
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
              datum: fuhrparkeintrag.datum,
            };
            if (fuhrparkeintrag.id) {
              // Update existing fuhrparkeintrag
              return updateFuhrparkeintrag(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                fuhrparkeintragData
              );
            } else {
              // Create new fuhrparkeintrag
              return createFuhrparkeintrag(
                taetigkeitsberichtId,
                fuhrparkeintragData
              );
            }
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId:
                    typeof fuhrparkeintragAnbauteil.anbauteil === "string"
                      ? fuhrparkeintragAnbauteil.anbauteil
                      : (
                          fuhrparkeintragAnbauteil.anbauteil as ZugewiesenesFahrzeugAnbauteil
                        )?.anbauteilId ?? "",
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                if (fuhrparkeintragAnbauteil.id) {
                  return updateFuhrparkeintragAnbauteil(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    fuhrparkeintragAnbauteil.id,
                    fuhrparkeintragAnbauteilData
                  );
                } else {
                  return createFuhrparkeintragAnbauteil(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    fuhrparkeintragAnbauteilData
                  );
                }
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        const maschinentransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.maschinentransporte.map(
              (maschinentransport) => {
                const abholortBaustellenId =
                  typeof maschinentransport.abholortBaustelle === "string"
                    ? maschinentransport.abholortBaustelle
                    : (maschinentransport.abholortBaustelle as Baustelle)?.id ??
                      "";
                const abstellortBaustellenId =
                  typeof maschinentransport.abstellortBaustelle === "string"
                    ? maschinentransport.abstellortBaustelle
                    : (maschinentransport.abstellortBaustelle as Baustelle)
                        .id ?? "";
                const maschinentransportData = {
                  baustelleId: updatedTaetigkeitsbericht.baustelleId,
                  transportiertesFahrzeugId:
                    typeof maschinentransport.transportiertesFahrzeug ===
                    "string"
                      ? maschinentransport.transportiertesFahrzeug
                      : (maschinentransport.transportiertesFahrzeug as Fahrzeug)
                          ?.id ?? "",
                  abholortBaustellenId: abholortBaustellenId,
                  abstellortBaustellenId: abstellortBaustellenId,
                  fuhrTyp: Buchungstyp.TR,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                };
                if (maschinentransport.id) {
                  return updateMaschinentransport(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    maschinentransport.id,
                    maschinentransportData
                  );
                } else {
                  return createMaschinentransport(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    maschinentransportData
                  );
                }
              }
            );
          }
        );

        await Promise.all(maschinentransportPromises);

        const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
              const quelleAbladestelleId =
                typeof materialanfuhr.quelleAbladestelle === "string"
                  ? materialanfuhr.quelleAbladestelle
                  : (materialanfuhr.quelleAbladestelle as Abladestelle)?.id ??
                    null;
              const materialId =
                typeof materialanfuhr.material === "string"
                  ? materialanfuhr.material
                  : (materialanfuhr.material as Material)?.id ?? null;
              const materialanfuhrData = {
                baustelleId: updatedTaetigkeitsbericht.baustelleId,
                quelleAbladestelleId: quelleAbladestelleId,
                zielBaustelleId: materialanfuhr.zielBaustelle,
                materialId: materialId,
                buchungsTyp: Buchungstyp.MA,
                fuhrTyp: Buchungstyp.MA,
                lieferscheinNummer: materialanfuhr.lieferscheinNummer,
                bemerkung: materialanfuhr.bemerkung,
                einheit: materialanfuhr.einheit,
                anzahlFuhren: materialanfuhr.anzahlFuhren,
                kostenProEinheit: materialanfuhr.kostenProEinheit,
                menge: materialanfuhr.menge,
                meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
                buchungsArt: materialanfuhr.buchungsArt,
              };
              if (materialanfuhr.id) {
                return updateMaterialtransport(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  materialanfuhr.id,
                  materialanfuhrData
                );
              } else {
                return createMaterialtransport(
                  updatedTaetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialanfuhrData
                );
              }
            });
          }
        );

        await Promise.all(materialanfuhrPromises);

        const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialabtransport.map(
              (materialtransport) => {
                const zielAbladestelleId =
                  typeof materialtransport.zielAbladestelle === "string"
                    ? materialtransport.zielAbladestelle
                    : (materialtransport.zielAbladestelle as Abladestelle)
                        ?.id ?? "";
                const materialId =
                  typeof materialtransport.material === "string"
                    ? materialtransport.material
                    : (materialtransport.material as Material)?.id ?? "";
                const entsorgerId =
                  typeof materialtransport.entsorger === "string"
                    ? materialtransport.entsorger
                    : (materialtransport.entsorger as Entsorger)?.id ?? null;
                const erzeugerId =
                  typeof materialtransport.erzeuger === "string"
                    ? materialtransport.erzeuger
                    : (materialtransport.erzeuger as Erzeuger)?.id ?? null;
                const materialanfuhrData = {
                  baustelleId: updatedTaetigkeitsbericht.baustelleId,
                  zielAbladestelleId: zielAbladestelleId,
                  materialId: materialId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  fuhrTyp: materialtransport.fuhrTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  menge: materialtransport.menge,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  bsNr: materialtransport.bsNr,
                  entsorgerId: entsorgerId,
                  erzeugerId: erzeugerId,
                };
                if (materialtransport.id) {
                  return updateMaterialtransport(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    materialtransport.id,
                    materialanfuhrData
                  );
                } else {
                  return createMaterialtransport(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    materialanfuhrData
                  );
                }
              }
            );
          }
        );

        await Promise.all(materialabtransportPromises);

        const materialtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialtransport.map(
              (materialtransport) => {
                const zielBaustelleId =
                  typeof materialtransport.zielBaustelle === "string"
                    ? materialtransport.zielBaustelle
                    : (materialtransport.zielBaustelle as Baustelle)?.id ??
                      null;
                const abfallartId =
                  typeof materialtransport.abfallart === "string"
                    ? materialtransport.abfallart
                    : (materialtransport.abfallart as Abfallart)?.id ?? null;
                const materialanfuhrData = {
                  baustelleId: updatedTaetigkeitsbericht.baustelleId,
                  zielBaustelleId: zielBaustelleId,
                  quelleBaustelleId: updatedTaetigkeitsbericht?.baustelleId,
                  materialId: materialtransport.material,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  menge: materialtransport.menge,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  abfallartId: abfallartId,
                };
                if (materialtransport.id) {
                  return updateMaterialtransportZwischenBaustellen(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    materialtransport.id,
                    materialanfuhrData
                  );
                } else {
                  return createMaterialtransportZwischenBaustellen(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    materialanfuhrData
                  );
                }
              }
            );
          }
        );

        await Promise.all(materialtransportPromises);

        setStatus(undefined);
        const fuhrparkeintraegeData = await searchFuhrparkeintraege(
          taetigkeitsberichtId,
          {
            search: taetigkeitsberichtId,
            page: 0,
            limit: 20,
            sort: "created",
          }
        );

        const completeFuhrparkeintraege = await Promise.all(
          fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
            const fuhrparkeintragAnbauteileData =
              await searchFuhrparkeintragAnbauteil(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );

            const maschinentransporteData = await searchMaschinentransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialanfuhrData = await searchMaterialtransportAnfuhr(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialabtransportData =
              await searchMaterialtransporteAbtransport(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );
            const materialzwischenData = await searchMaterialZwischenBaustellen(
              taetigkeitsberichtId,
              fuhrparkeintrag.id
            );
            const materialanfuhrListe = materialanfuhrData.items;
            const abtranporteListe = materialabtransportData.items;

            return {
              ...fuhrparkeintrag,
              fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
              maschinentransporte: maschinentransporteData.items,
              materialanfuhr: materialanfuhrListe,
              materialabtransport: abtranporteListe,
              materialtransport: materialzwischenData.items,
            };
          })
        );

        // @ts-ignore
        setFuhrparkeintraege(completeFuhrparkeintraege);
        const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
        setTaetigkeitsbericht(data);
        const dataPersonaleintrag = await getPersonaleintrag(
          taetigkeitsberichtId
        );
        setPersonaleintrag(dataPersonaleintrag);
        resetForm({
          values: {
            ...values,
            // @ts-ignore
            fuhrparkeintraege: fuhrparkeintraege.map((fuhrparkeintrag) => ({
              id: fuhrparkeintrag.id,
              // @ts-ignore
              fahrzeug: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
              datum: fuhrparkeintrag.datum,
              fuhrparkeintragAnbauteile:
                fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
                  (fuhrparkeintragAnbauteil) => ({
                    id: fuhrparkeintragAnbauteil.id,
                    // @ts-ignore
                    anbauteil: fuhrparkeintragAnbauteil.anbauteilId,
                    anbauteilNutzungsdauer:
                      fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                    anbauteilStundenPreis:
                      fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                    anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  })
                ),
              maschinentransporte: fuhrparkeintrag.maschinentransporte.map(
                (maschinentransport) => ({
                  id: maschinentransport.id,
                  baustelleId: maschinentransport.baustelleId,
                  transportiertesFahrzeug:
                    // @ts-ignore
                    maschinentransport.transportiertesFahrzeugId,
                  // @ts-ignore
                  abholortBaustelle: maschinentransport.abholortBaustellenId,
                  abstellortBaustelle:
                    // @ts-ignore
                    maschinentransport.abstellortBaustellenId,
                  fuhrTyp: maschinentransport.fuhrTyp,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                })
              ),
              materialanfuhr: fuhrparkeintrag.materialanfuhr.map(
                (materialanfuhr) => ({
                  id: materialanfuhr.id,
                  baustelleId: materialanfuhr.baustelleId,
                  // @ts-ignore
                  quelleAbladestelle: materialanfuhr.quelleAbladestelleId,
                  // @ts-ignore
                  zielBaustelle: materialanfuhr.zielBaustelleId,
                  // @ts-ignore
                  material: materialanfuhr.materialId,
                  preisGesamt: materialanfuhr.preisGesamt,
                  mengeGesamt: materialanfuhr.mengeGesamt,
                  menge: materialanfuhr.menge,
                  fuhrTyp: materialanfuhr.fuhrTyp,
                  einheit: materialanfuhr.einheit,
                  lieferscheinNummer: materialanfuhr.lieferscheinNummer,
                  bemerkung: materialanfuhr.bemerkung,
                  meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
                  buchungsArt: materialanfuhr.buchungsArt,
                  kostenProEinheit: materialanfuhr.kostenProEinheit,
                  anzahlFuhren: materialanfuhr.anzahlFuhren,
                  // @ts-ignore
                  abfallart: materialanfuhr.abfallartId,
                })
              ),
              materialabtransport: fuhrparkeintrag.materialabtransport.map(
                (materialabtransport) => ({
                  id: materialabtransport.id,
                  baustelleId: materialabtransport.baustelleId,
                  fuhrTyp: materialabtransport.fuhrTyp,
                  buchungsTyp: materialabtransport.buchungsTyp,
                  // @ts-ignore
                  zielAbladestelle: materialabtransport.zielAbladestelleId,
                  // @ts-ignore
                  zielBaustelle: materialabtransport.zielBaustelleId,
                  // @ts-ignore
                  quelleBaustelle: materialabtransport.quelleBaustelleId,
                  // @ts-ignore
                  material: materialabtransport.materialId,
                  preisGesamt: materialabtransport.preisGesamt,
                  mengeGesamt: materialabtransport.mengeGesamt,
                  menge: materialabtransport.menge,
                  einheit: materialabtransport.einheit,
                  lieferscheinNummer: materialabtransport.lieferscheinNummer,
                  bemerkung: materialabtransport.bemerkung,
                  meLadeVolumenFahrzeug:
                    materialabtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialabtransport.buchungsArt,
                  bsNr: materialabtransport.bsNr,
                  // @ts-ignore
                  entsorger: materialabtransport.entsorgerId,
                  // @ts-ignore
                  erzeuger: materialabtransport.erzeugerId,
                  kostenProEinheit: materialabtransport.kostenProEinheit,
                  anzahlFuhren: materialabtransport.anzahlFuhren,
                  // @ts-ignore
                  abfallart: materialabtransport.abfallartId,
                })
              ),
              materialtransport: fuhrparkeintrag.materialtransport.map(
                (materialtransport) => ({
                  id: materialtransport.id,
                  baustelleId: data.baustelleId,
                  // @ts-ignore
                  zielBaustelle: materialtransport.zielBaustelleId,
                  // @ts-ignore
                  material: materialtransport.materialId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  menge: materialtransport.menge,
                  preisGesamt: materialtransport.preisGesamt,
                  gesamtMenge: materialtransport.mengeGesamt,
                  buchungsArt: materialtransport.buchungsArt,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  // @ts-ignore
                  abfallart: materialtransport.abfallartId,
                })
              ),
            })),
          },
        });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        if (alterTaetigkeitsberichtstatus === "OFFEN") {
          navigate(`/berichtfreigabe/`, { replace: true });
        } else {
          navigate(`/taetigkeitsberichte/`, { replace: true });
        }
        setSubmitting(false);
      }
    },
    [
      navigate,
      updateTaetigkeitsbericht,
      updatePersonaleintrag,
      createFuhrparkeintrag,
      updateFuhrparkeintrag,
      taetigkeitsberichtId,
    ]
  );

  if (!taetigkeitsbericht || !personaleintrag) {
    return <div>Loading...</div>;
  }

  const backRoute = berichtOffen ? "/berichtfreigabe" : "/taetigkeitsberichte";

  const leererFp = {
    fahrzeug: "",
    fahrzeugHerkunft: "",
    fahrzeugStundenPreis: 0,
    fahrzeugNutzungsdauer: 0,
    bemerkung: "",
    datum: "",
    fuhrparkeintragAnbauteile: [
      {
        anbauteil: "",
        anbauteilNutzungsdauer: 0,
        anbauteilAnzahl: 1,
        anbauteilStundenPreis: 0,
        anbauteilKosten: 0,
      },
    ],
    maschinentransporte: [],
    materialanfuhr: [],
    materialabtransport: [],
    materialtransport: [],
  };

  const fpArray = fuhrparkeintraege.map((fuhrparkeintrag) => ({
    id: fuhrparkeintrag.id,
    // @ts-ignore
    fahrzeug: fuhrparkeintrag.fahrzeugId,
    fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
    fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
    fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
    bemerkung: fuhrparkeintrag.bemerkung,
    datum: fuhrparkeintrag.datum,
    fuhrparkeintragAnbauteile: fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
      (fuhrparkeintragAnbauteil) => ({
        id: fuhrparkeintragAnbauteil.id,
        // @ts-ignore
        anbauteil: fuhrparkeintragAnbauteil.anbauteilId,
        anbauteilNutzungsdauer: fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
        anbauteilStundenPreis: fuhrparkeintragAnbauteil.anbauteilStundenPreis,
        anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
      })
    ),
    maschinentransporte: fuhrparkeintrag.maschinentransporte.map(
      (maschinentransport) => ({
        id: maschinentransport.id,
        baustelleId: maschinentransport.baustelleId,
        transportiertesFahrzeug:
          // @ts-ignore
          maschinentransport.transportiertesFahrzeugId,
        // @ts-ignore
        abholortBaustelle:
          // @ts-ignore
          maschinentransport.abholortBaustellenId,
        abstellortBaustelle:
          // @ts-ignore
          maschinentransport.abstellortBaustellenId,
        fuhrTyp: maschinentransport.fuhrTyp,
        lieferscheinNummer: maschinentransport.lieferscheinNummer,
        bemerkung: maschinentransport.bemerkung,
      })
    ),
    materialanfuhr: fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => ({
      id: materialanfuhr.id,
      baustelleId: materialanfuhr.baustelleId,
      // @ts-ignore
      quelleAbladestelle: materialanfuhr.quelleAbladestelleId,
      // @ts-ignore
      zielBaustelle: materialanfuhr.zielBaustelleId,
      // @ts-ignore
      material: materialanfuhr.materialId,
      fuhrTyp: materialanfuhr.fuhrTyp,
      einheit: materialanfuhr.einheit,
      menge: materialanfuhr.menge,
      mengeGesamt: materialanfuhr.mengeGesamt,
      kostenProEinheit: materialanfuhr.kostenProEinheit,
      anzahlFuhren: materialanfuhr.anzahlFuhren,
      preisGesamt: materialanfuhr.preisGesamt,
      lieferscheinNummer: materialanfuhr.lieferscheinNummer,
      bemerkung: materialanfuhr.bemerkung,
      meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
      buchungsArt: materialanfuhr.buchungsArt,
      // @ts-ignore
      abfallart: materialanfuhr.abfallartId,
    })),
    materialabtransport: fuhrparkeintrag.materialabtransport.map(
      (materialabtransport) => ({
        id: materialabtransport.id,
        baustelleId: materialabtransport.baustelleId,
        // @ts-ignore
        zielAbladestelle: materialabtransport.zielAbladestelleId,
        // @ts-ignore
        zielBaustelle: materialabtransport.zielBaustelleId,
        // @ts-ignore
        quelleBaustelle: materialabtransport.quelleBaustelleId,
        // @ts-ignore
        material: materialabtransport.materialId,
        fuhrTyp: materialabtransport.fuhrTyp,
        einheit: materialabtransport.einheit,
        menge: materialabtransport.menge,
        mengeGesamt: materialabtransport.mengeGesamt,
        kostenProEinheit: materialabtransport.kostenProEinheit,
        anzahlFuhren: materialabtransport.anzahlFuhren,
        preisGesamt: materialabtransport.preisGesamt,
        lieferscheinNummer: materialabtransport.lieferscheinNummer,
        bemerkung: materialabtransport.bemerkung,
        meLadeVolumenFahrzeug: materialabtransport.meLadeVolumenFahrzeug,
        buchungsArt: materialabtransport.buchungsArt,
        bsNr: materialabtransport.bsNr,
        // @ts-ignore
        entsorger: materialabtransport.entsorgerId,
        // @ts-ignore
        erzeuger: materialabtransport.erzeugerId,
        buchungsTyp: materialabtransport.buchungsTyp,
        // @ts-ignore
        abfallart: materialabtransport.abfallartId,
      })
    ),
    materialtransport: fuhrparkeintrag.materialtransport.map(
      (materialabtransport) => ({
        id: materialabtransport.id,
        // @ts-ignore
        zielBaustelle: materialabtransport.zielBaustelleId,
        // @ts-ignore
        quelleBaustelle: materialabtransport.quelleBaustelleId,
        materialId: materialabtransport.material,
        einheit: materialabtransport.einheit,
        menge: materialabtransport.menge,
        mengeGesamt: materialabtransport.mengeGesamt,
        kostenProEinheit: materialabtransport.kostenProEinheit,
        anzahlFuhren: materialabtransport.anzahlFuhren,
        preisGesamt: materialabtransport.preisGesamt,
        lieferscheinNummer: materialabtransport.lieferscheinNummer,
        bemerkung: materialabtransport.bemerkung,
        meLadeVolumenFahrzeug: materialabtransport.meLadeVolumenFahrzeug,
        buchungsArt: materialabtransport.buchungsArt,
        buchungsTyp: materialabtransport.buchungsTyp,
        // @ts-ignore
        abfallart: materialabtransport.abfallartId,
      })
    ),
  }));

  // @ts-ignore
  return (
    <Layout title={capitalize(t("activity-report"))} back={backRoute}>
      <Guard
        permission={"taetigkeitsbericht:edit"}
        fallback={<ErrorAlert error={notPermitted()} />}
      >
        <Paper
          elevation={1}
          sx={{
            marginTop: 2,
            padding: 3,
            marginLeft: 2,
            marginRight: 2,
            position: "relative",
          }}
        >
          {taetigkeitsbericht.deleted === undefined ? (
            <Guard permission={"taetigkeitsbericht:delete"}>
              <DeleteTaetigkeitsberichtButton
                id={taetigkeitsberichtId}
              ></DeleteTaetigkeitsberichtButton>
            </Guard>
          ) : (
            <></>
          )}
          <Formik
            enableReinitialize={true}
            initialValues={{
              mitarbeiter: personaleintrag?.mitarbeiterId ?? "",
              baustelle: taetigkeitsbericht?.baustelleId ?? "",
              datum: taetigkeitsbericht?.datum ?? "",
              arbeitszeitNetto: personaleintrag?.arbeitszeitNetto ?? null,
              stundensatz: personaleintrag?.stundensatz ?? 0,
              lohnkosten: personaleintrag?.lohnkosten ?? 0,
              bemerkung: personaleintrag?.bemerkung ?? "",
              bearbeitetUm:
                timestampToDatetimeLocal(
                  taetigkeitsbericht?.updated?.timestamp
                ) ?? "",
              // @ts-ignore
              fuhrparkeintraege: addedFPInherited
                ? [...fpArray, leererFp]
                : fpArray,
            }}
            validationSchema={validationSchema}
            // @ts-ignore
            onSubmit={onSubmit}
          >
            {({ isSubmitting, isValidating, isValid, dirty, values }) => (
              <>
                <DialogContent>
                  <Typography>
                    {capitalize(t("activity-report-staff"))}
                    {taetigkeitsbericht.deleted === undefined ? (
                      ""
                    ) : (
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        label={"gelöscht"}
                        color="error"
                      />
                    )}
                  </Typography>
                  <Form id="details-taetigkeitsbericht">
                    <TaetigkeitsberichtFieldsContent isNew={false} />
                    <FieldArray name="fuhrparkeintraege">
                      {({ push, remove }) => (
                        <>
                          {values.fuhrparkeintraege.map(
                            (fuhrparkeintrag, index) => (
                              <Box key={index} sx={{ marginTop: 2 }}>
                                <Box
                                  display={"grid"}
                                  sx={{
                                    gridTemplateColumns: ["1fr", "10fr .1fr"],
                                    gap: 2,
                                    marginTop: 2,
                                  }}
                                >
                                  <ExpandContextProvider
                                    id={`fuhrparkeintrag-${index}`}
                                  >
                                    <FuhrparkeintragEigenPersonalItem
                                      isNew={!fuhrparkeintrag.id}
                                      // @ts-ignore
                                      fuhrparkeintrag={fuhrparkeintrag}
                                      index={index}
                                      expanded={expandedItems[index] ?? true}
                                      handleChange={handleAccordionChange}
                                      taetigkeitsberichtDatum={values.datum}
                                      dauerVorhanden={dauerVorhanden}
                                      isValidating={isValidating}
                                      isValid={isValid}
                                      remove={() => remove(index)}
                                      taetigkeitsberichtId={
                                        taetigkeitsberichtId
                                      }
                                    />
                                  </ExpandContextProvider>
                                </Box>
                              </Box>
                            )
                          )}
                          <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                            <Button
                              variant="contained"
                              color={berichtOffen ? "success" : "secondary"}
                              onClick={async () => {
                                const newEntry = {
                                  fahrzeug: "",
                                  fahrzeugHerkunft: "",
                                  fahrzeugStundenPreis: 0,
                                  fahrzeugNutzungsdauer: 0,
                                  bemerkung: "",
                                  datum: "",
                                  fuhrparkeintragAnbauteile: [
                                    {
                                      anbauteil: "",
                                      anbauteilNutzungsdauer: 0,
                                      anbauteilAnzahl: 1,
                                      anbauteilStundenPreis: 0,
                                      anbauteilKosten: 0,
                                    },
                                  ],
                                  maschinentransporte: [],
                                  materialanfuhr: [],
                                  materialabtransport: [],
                                  materialtransport: [],
                                };

                                setExpandedItems((prevState) => {
                                  const newExpandedItems = [...prevState];
                                  newExpandedItems[
                                    values.fuhrparkeintraege.length
                                  ] = true;
                                  return newExpandedItems;
                                });

                                if (values.fuhrparkeintraege.length > 0) {
                                  // @ts-ignore
                                  saveFuhrparkeintraege(values);
                                  setBerichtOffen(false);
                                  push(newEntry);
                                } else {
                                  push(newEntry);
                                }
                              }}
                              disabled={isValidating || !isValid}
                            >
                              {berichtOffen
                                ? capitalize(t("release-and-add-carpool"))
                                : capitalize(t("add-carpool"))}
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  {berichtOffen ? (
                    <SubmitButton
                      form="details-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="success"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("to-release"))}
                    </SubmitButton>
                  ) : (
                    <SubmitButton
                      form="details-taetigkeitsbericht"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      loading={isSubmitting}
                      disabled={isValidating || !isValid || !dirty}
                    >
                      {capitalize(t("save"))}
                    </SubmitButton>
                  )}
                </DialogActions>
              </>
            )}
          </Formik>
        </Paper>
      </Guard>
    </Layout>
  );
}

function timestampToDatetimeLocal(timestamp: any) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
