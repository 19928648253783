/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAnhang } from '../models/CreateAnhang';
import type { Dublette } from '../models/Dublette';
import type { EingangsrechnungenBadge } from '../models/EingangsrechnungenBadge';
import type { Rechnung } from '../models/Rechnung';
import type { RechnungDaten } from '../models/RechnungDaten';
import type { RechnungenListe } from '../models/RechnungenListe';
import type { RechnungsAnhang } from '../models/RechnungsAnhang';
import type { UpdateZugewieseneRechnungBaustelleDaten } from '../models/UpdateZugewieseneRechnungBaustelleDaten';
import type { ZugewieseneRechnungBaustelle } from '../models/ZugewieseneRechnungBaustelle';
import type { ZugewieseneRechnungBaustelleDaten } from '../models/ZugewieseneRechnungBaustelleDaten';
import type { ZugewieseneRechnungBaustellenListe } from '../models/ZugewieseneRechnungBaustellenListe';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RechnungenService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns RechnungenListe Liste der Rechnungen
   * @throws ApiError
   */
  public sucheRechnungen({
    search,
    status,
    pruefer,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    status?: string,
    pruefer?: string,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<RechnungenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rechnungen',
      query: {
        'search': search,
        'status': status,
        'pruefer': pruefer,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Rechnung die angelegte Rechnung
   * @throws ApiError
   */
  public createRechnung({
    requestBody,
  }: {
    requestBody: RechnungDaten,
  }): CancelablePromise<Rechnung> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/rechnungen',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns RechnungenListe Liste der Rechnungen gefiltert auf Eingangsrechnungen
   * @throws ApiError
   */
  public sucheEingangsrechnungen({
    search,
    status,
    zahllauf,
    zahllaufBezahlt,
    filtered,
    page,
    limit,
    sort,
  }: {
    search?: string,
    status?: string,
    zahllauf?: string,
    zahllaufBezahlt?: boolean,
    filtered?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<RechnungenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eingangsrechnungen',
      query: {
        'search': search,
        'status': status,
        'zahllauf': zahllauf,
        'zahllaufBezahlt': zahllaufBezahlt,
        'filtered': filtered,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Rechnung Rechnung
   * @throws ApiError
   */
  public getRechnung({
    rechnungId,
  }: {
    rechnungId: string,
  }): CancelablePromise<Rechnung> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rechnungen/{rechnungId}',
      path: {
        'rechnungId': rechnungId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Rechnung die aktualisierte Rechnung
   * @throws ApiError
   */
  public updateRechnung({
    rechnungId,
    requestBody,
  }: {
    rechnungId: string,
    requestBody: RechnungDaten,
  }): CancelablePromise<Rechnung> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/rechnungen/{rechnungId}',
      path: {
        'rechnungId': rechnungId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Rechnung die gelöschte Rechnung
   * @throws ApiError
   */
  public deleteRechnung({
    rechnungId,
  }: {
    rechnungId: string,
  }): CancelablePromise<Rechnung> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/rechnungen/{rechnungId}',
      path: {
        'rechnungId': rechnungId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns Dublette Dubelettenpruefung
   * @throws ApiError
   */
  public getRechnungsDublette({
    rechnungId,
    rechnungsNummer,
    lieferantenId,
  }: {
    rechnungId: string,
    rechnungsNummer?: string,
    lieferantenId?: string,
  }): CancelablePromise<Dublette> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rechnungen/{rechnungId}/duplicate-check',
      path: {
        'rechnungId': rechnungId,
      },
      query: {
        'rechnungsNummer': rechnungsNummer,
        'lieferantenId': lieferantenId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns RechnungsAnhang das angelegte Rechnungsanhang
   * @throws ApiError
   */
  public createRechnungsAnhang({
    formData,
  }: {
    formData: CreateAnhang,
  }): CancelablePromise<RechnungsAnhang> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/rechnungen/anhaenge',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns ZugewieseneRechnungBaustellenListe Liste der zugewiesenen Baustellen
   * @throws ApiError
   */
  public getZugewieseneBaustellen({
    rechnungsId,
    search,
    page,
    limit,
    sort,
  }: {
    rechnungsId: string,
    search?: string,
    page?: number,
    limit?: number,
    sort?: string,
  }): CancelablePromise<ZugewieseneRechnungBaustellenListe> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rechnungen/{rechnungsId}/zugewiesene-baustellen',
      path: {
        'rechnungsId': rechnungsId,
      },
      query: {
        'search': search,
        'page': page,
        'limit': limit,
        'sort': sort,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns ZugewieseneRechnungBaustelle die zugewiesene Baustelle
   * @throws ApiError
   */
  public assignBaustelleZuRechnung({
    rechnungsId,
    requestBody,
  }: {
    rechnungsId: string,
    requestBody: ZugewieseneRechnungBaustelleDaten,
  }): CancelablePromise<ZugewieseneRechnungBaustelle> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/rechnungen/{rechnungsId}/zugewiesene-baustellen',
      path: {
        'rechnungsId': rechnungsId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns ZugewieseneRechnungBaustelle die zugewiesene Baustelle
   * @throws ApiError
   */
  public getZugewieseneBaustelle({
    rechnungsId,
    zugewieseneBaustellenId,
  }: {
    rechnungsId: string,
    zugewieseneBaustellenId: string,
  }): CancelablePromise<ZugewieseneRechnungBaustelle> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rechnungen/{rechnungsId}/zugewiesene-baustellen/{zugewieseneBaustellenId}',
      path: {
        'rechnungsId': rechnungsId,
        'zugewieseneBaustellenId': zugewieseneBaustellenId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns ZugewieseneRechnungBaustelle die aktualisierte zugewiesene Baustelle
   * @throws ApiError
   */
  public updateZugewieseneBaustelle({
    rechnungsId,
    zugewieseneBaustellenId,
    requestBody,
  }: {
    rechnungsId: string,
    zugewieseneBaustellenId: string,
    requestBody: UpdateZugewieseneRechnungBaustelleDaten,
  }): CancelablePromise<ZugewieseneRechnungBaustelle> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/rechnungen/{rechnungsId}/zugewiesene-baustellen/{zugewieseneBaustellenId}',
      path: {
        'rechnungsId': rechnungsId,
        'zugewieseneBaustellenId': zugewieseneBaustellenId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * @returns ZugewieseneRechnungBaustelle die gelöschte zugewiesene Baustelle
   * @throws ApiError
   */
  public deleteZugewieseneBaustelle({
    rechnungsId,
    zugewieseneBaustellenId,
  }: {
    rechnungsId: string,
    zugewieseneBaustellenId: string,
  }): CancelablePromise<ZugewieseneRechnungBaustelle> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/rechnungen/{rechnungsId}/zugewiesene-baustellen/{zugewieseneBaustellenId}',
      path: {
        'rechnungsId': rechnungsId,
        'zugewieseneBaustellenId': zugewieseneBaustellenId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }

  /**
   * @returns EingangsrechnungenBadge Die Anzahl der Eingangsrechnungen in Pruefung
   * @throws ApiError
   */
  public getEingangsrechnungenInPruefungBadge(): CancelablePromise<EingangsrechnungenBadge> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/eingangsrechungen/badge',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

}
