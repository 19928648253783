import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import DataTable from "../data/DataTable";
import { Baustelle, BaustellenInfo, Umsatz } from "../../api";
import DataTableWithFilter from "../data/DataTableWithFilter";
import { EingangsrechnungenFilter } from "../eingangsrechnungen/EIngangsrechnungenDataTable";

export type VorgaengeFilter = {
  search?: string;
  baustellenId?: string;
  isTaetigkeitsbericht?: boolean;
  filtered?: string;
};

export type Props = Omit<DataRequest<VorgaengeFilter>, "filter"> &
  Partial<Pick<DataRequest<VorgaengeFilter>, "filter">> & {
    baustellenId: string; // Add baustellenId as a required prop
  };

export default function VorgaengeDataTable({ baustellenId, ...input }: Props) {
  const request = useDataRequest<VorgaengeFilter>({
    filter: { baustellenId: baustellenId },
    ...input,
  });
  const [filteredState, setFilteredState] = useState("");
  return (
    <Stack spacing={2}>
      <FilterComp
        request={request}
        filteredStateFilter={filteredState}
        setFilteredState={setFilteredState}
      />
      <VorgaengeResults request={request} setFilteredState={setFilteredState} />
    </Stack>
  );
}

function FilterComp({
  request,
  filteredStateFilter,
  setFilteredState,
}: {
  request: DataRequestState<VorgaengeFilter>;
  filteredStateFilter: string;
  setFilteredState: (value: string) => void;
}) {
  const [{ search, isTaetigkeitsbericht, baustellenId, filtered }, setField] =
    useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      setField("baustellenId", id);
    }
  }, [id, setField, search]);
  useEffect(() => {
    if (filteredStateFilter) {
      setField("filtered", filteredStateFilter);
    }
  }, [filteredStateFilter]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => {
              setField("search", s);
            }}
          />
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                color="primary"
                checked={isTaetigkeitsbericht}
                onChange={(e) =>
                  setField("isTaetigkeitsbericht", e.target.checked)
                }
              />
            }
            label={"Nur Tätigkeitsberichte"}
          />
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => {
              setField("search", s);
            }}
          />
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                color="primary"
                checked={isTaetigkeitsbericht}
                onChange={(e) =>
                  setField("isTaetigkeitsbericht", e.target.checked)
                }
              />
            }
            label={"Nur Tätigkeitsberichte"}
          />
        </>
      )}
    </Box>
  );
}

function VorgaengeResults({
  request,
  setFilteredState,
}: {
  request: DataRequestState<VorgaengeFilter>;
  setFilteredState: (value: string) => void;
}) {
  return <Results request={request} setFilteredState={setFilteredState} />;
}

function Results({
  request,
  setFilteredState,
}: {
  request: DataRequestState<VorgaengeFilter>;
  setFilteredState: (value: string) => void;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadUmsatz: DataLoader<VorgaengeFilter, Umsatz> = useCallback(
    async (params) => (await getApi()).umsatz.sucheUmsatz({ ...params }),
    [getApi]
  );
  return (
    <DataTableWithFilter
      columns={useColumns()}
      request={request}
      queryKey={["umsaetze"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadUmsatz}
      onRowClick={(row: GridRowParams<Umsatz>) =>
        navigate(`${row.row.referenzUrl}`)
      }
      name={"umsaetze-" + request.filter?.baustellenId}
      setFiltered={setFilteredState}
    />
  );
}
function useColumns(): Array<GridColDef<Umsatz>> {
  const { t, capitalize } = useT("vorgaenge");

  return useMemo(
    () => [
      {
        field: "buchungsDatum",
        headerName: t("booking-date"),
        flex: 1,
        type: "date",
        valueGetter: (value) => {
          return new Date(value);
        },
        renderCell: ({ value }) =>
          new Date(value as string).toLocaleDateString(undefined, {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }),
      },
      {
        field: "buchungsArt",
        headerName: capitalize(t("booking-type")),
        flex: 1,
      },
      {
        field: "buchungsTyp",
        headerName: capitalize(t("booking-short-type")),
        flex: 1,
      },
      {
        field: "vorgangBezeichnung",
        headerName: capitalize(t("description")),
        flex: 2,
      },
      {
        field: "materialName",
        headerName: capitalize(t("material")),
        flex: 2,
      },
      {
        field: "eakSchluessel",
        headerName: capitalize(t("eak-nr")),
        flex: 1,
      },
      {
        field: "gesamtmenge",
        headerName: capitalize(t("total-units")),
        align: "right",
        headerAlign: "right",
        flex: 1,
      },
      {
        field: "mengeneinheit",
        headerName: capitalize(t("unit")),
        flex: 1,
      },
      {
        field: "lieferscheinNr",
        headerName: capitalize(t("delivery-note-number")),
        flex: 2,
      },
      {
        field: "betrag",
        headerName: capitalize(t("total-amount")),
        flex: 1.5,
        align: "right",
        headerAlign: "right",
        type: "number",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "bemerkungTb",
        headerName: capitalize(t("remark")),
        flex: 2,
      },
    ],
    [t]
  );
}
